<template>
  <b-container fluid class="chat p-0">
    <b-row class="chat-tickets__form">
      <b-col md="12">
        <b-form-row>
          <b-form-group
            :label="$t(`chat.form.carPlate.label`)"
            class="chat-tickets__form-plate-input col-md-3 col-sm-12"
          >
            <b-form-input
              v-model="carPlate"
              :placeholder="$t(`chat.form.carPlate.label`)"
              :disabled="block_ui"
            />
          </b-form-group>

          <b-form-group
            :label="$t(`chat.form.perPage.label`)"
            class="col-md-2 col-sm-12"
          >
            <b-form-select
              :value="paginationConfig.perPage"
              :options="perPageOptions"
              :disabled="block_ui"
              @change="setPerPage"
            />
          </b-form-group>

          <b-form-group
            :label="$t(`chat.form.provider.label`)"
            class="col-md-2 col-sm-12"
          >
            <b-form-select
              v-model="provider"
              :options="providersOptions"
              :disabled="block_ui"
            />
          </b-form-group>

          <b-form-group
            :label="$t(`chat.form.openingDate.label`)"
            class=""
          >
            <datepicker
              v-model="openingDate"
              v-bind="date_picker_props"
              :lang="getLanguage"
              :disabled="block_ui"
            />
          </b-form-group>

          <b-form-group
            :label="$t(`chat.form.protocol_number.label`)"
            class="chat-tickets__form-protocol_number-input col-md-3 col-sm-12"
          >
            <b-form-input
              v-model="protocol_number"
              :placeholder="$t(`chat.form.protocol_number.label`)"
              :disabled="block_ui"
            />
          </b-form-group>

          <b-form-group
            class="col-md-1 col-sm-12"
          >
            <b-button
              type="submit"
              variant="info"
              class="chat-tickets__form-button"
              block
              :disabled="block_ui"
              @click="getTickets(true)"
            >
              <i class="fa fa-search" />
            </b-button>
          </b-form-group>
        </b-form-row>
      </b-col>
    </b-row>

    <b-row
      v-if="loading"
      class="chat-tickets__loader"
    >
      <b-col md="12">
        <transition name="fade">
          <div class="chat-tickets__loader-wrapper">
            <i class="chat-tickets__loader-icon fa fa-spin fa-cog" />
          </div>
        </transition>
      </b-col>
    </b-row>

    <b-row
      v-if="!loading && (!hasTickets || hasErrorTicket)"
      class="chat-tickets__result-messages"
    >
      <b-col
        md="12"
        class="chat-tickets__result-message"
      >
        {{ $t('chat.messages.noItems') }}
      </b-col>
    </b-row>

    <b-row
      v-else
      class="chat-tickets__list"
      :style="{ 'max-height': `${randomHeight}px` }"
    >
      <b-col
        :xs="responsiveTicketsWidth"
        :sm="responsiveTicketsWidth"
        :md="responsiveTicketsWidth"
        :class="[ 'chat-tickets__list-cards', isOpenedTicket ? 'chat-tickets__list-cards--opened' : '' ]"
      >
        <b-card
          v-for="(ticket, index) in tickets"
          :key="index"
          no-body
          :class="[ 'chat-tickets__list-card', (activeTicket === ticket.id) ? 'chat-tickets__list-card--active' : '' ]"
        >
          <div class="chat-tickets__card-content">
            <div class="chat-tickets__date-content">
              <div class="chat-tickets__date-content-text">
                <p :style="{ color: ticket.statusColor }">
                  {{ ticket.created_at.date | formatDayMonth }}
                </p>
              </div>

              <span class="chat-tickets__date-content-separator" :style="{ background: ticket.statusColor }" />
            </div>

            <div class="chat-tickets__driver-content">
              <span class="chat-tickets-protocol">{{ ticket.protocol_number }}</span>
              <p class="chat-tickets__driver-content-count-status">
                <span class="chat-tickets__driver-content-count">
                  {{ ticket.messages.length }} {{ $t(`chat.tickets.count.${ticket.messages.length === 1 ? 'one' : 'others'}`) }}
                </span>
              </p>

              <p>
                <span :class="[ 'chat-tickets__driver-status', `chat-tickets__driver-status--${ticket.status}` ]">{{ $t(`chat.status.${ticket.status}`) }}</span>
                <span class="chat-tickets__driver-content-provider">{{ $t(`chat.providers.${ticket.provider}`) }}</span>
                <span class="chat-tickets__driver-content-comments fa fa-comments" :title="$t(`chat.icons.comments`)"
                      @click="openTicket('comments', ticket.id)"
                />
                <span class="chat-tickets__driver-content-tags fa fa-tags" :title="$t(`chat.icons.tags`)"
                      @click="openTicket('tags', ticket.id)"
                />
              </p>

              <b-button variant="link" :disabled="block_ui" class="chat-tickets__driver-button-view pl-0 pr-0"
                        @click="openTicket('ticket', ticket.id)"
              >
                <div class="chat-tickets__driver-icons">
                  <i class="chat-tickets-icon-eyes-icon fa fa-eye" :title="$t(`chat.icons.eyes`)" />
                </div>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col
        v-if="isOpenedTicket"
        :xs="responsiveTicketWidth"
        :sm="responsiveTicketWidth"
        :md="responsiveTicketWidth"
        :class="[ 'chat-tickets__list-tickets', 'no-padding', isOpenedTicket ? 'chat-tickets__ticket--opened' : '' ]"
        :style="{ 'max-height': `${randomHeight - 12}px` }"
      >
        <div class=" chat-tickets__ticket-button">
          <b-button
            class="chat_tickets__ticket-close-button"
            :disabled="block_ui"
            @click="closeTicket"
          >
            X
          </b-button>
        </div>

        <div v-if="loadingMessage" class="chat-tickets__ticket-loader">
          <transition name="fade">
            <div class="chat-tickets__loader-wrapper">
              <i class="chat-tickets__loader-icon fa fa-spin fa-cog" />
            </div>
          </transition>
        </div>

        <div v-else-if="loadedMessage && hasContent" class="chat_tickets__items" :style="{ height: (openedType === 'comments') ? '80%' : '' }">
          <div v-if="openedType === 'comments'" class="chat_tickets__comment">
            <div
              v-for="(message, index) in ticketsContent"
              :key="index"
              class="chat_tickets__comment-box chat_tickets__comment-driver-box"
            >
              <p class="chat_tickets__comment-box-agent">
                {{ message.support_agent_email }}
              </p>

              <div class="chat_tickets__comment-box-content">
                <a v-if="message.file_comment" class="chat_tickets__comment-content-link" :href="message.file_comment"
                   target="_blank"
                >
                  <img v-if="pdfOrImage(message.file_comment) === 'image'" :src="message.file_comment" class="chat_tickets__content-link-image">
                  <i v-else class="chat_tickets__content-link-icon fa fa-file-pdf-o" />
                </a>

                <p v-if="message.comment !== 'without comment'" class="chat_tickets__comment-content-message">
                  {{ message.comment }}
                </p>
              </div>

              <p class="chat_tickets__comment-box-date">
                {{ formatDate(message.created_at.date) }}
              </p>
            </div>
          </div>

          <div v-else-if="openedType === 'tags'" class="chat_tickets__tags">
            <div
              v-for="(tag, index) in ticketsContent"
              :key="index"
              class="chat_tickets__tags-box chat_tickets__tags-box"
            >
              <div v-if="!tag.SupportTicketsTag.is_active" class="chat_tickets__no-tags-active">
                <span>{{ $t('chat.messages.noTagsActive') }}</span>
                <span />
              </div>

              <div v-if="tag.SupportTicketsTag.is_active" class="chat_tickets__tags-box-content">
                <div class="chat_tickets__tags-header">
                  <p>
                    {{ tag.name }}
                  </p>
                </div>
                <div class="chat_tickets__tags-body">
                  <p>
                    <i class="fa fa-calendar">
                      {{ formatDate(tag.SupportTicketsTag.created_at.date) }}
                    </i>
                  </p>
                  <p>
                    <i class="fa fa-user">
                      {{ tag.SupportTicketsTag.support_agent_email }}
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="chat_tickets__ticket">
            <div
              v-for="(message, index) in ticketsContent"
              :key="index"
              :class="[ 'chat_tickets__ticket-box', ['STUDIO', 'CHATBOT'].includes(message.sender) ? 'chat_tickets__ticket-bot-box' : 'chat_tickets__ticket-driver-box' ]"
            >
              <p class="chat_tickets__ticket-box-sender">
                <span v-if="message.sender === 'CHATBOT' && message.receiver === 'DRIVER'">{{ $t('chat.ticket.sender.chatbot') }}</span>
                <span v-else-if="message.sender === 'STUDIO' && message.receiver === 'DRIVER'">{{ $t('chat.ticket.sender.studio') }}</span>
                <span v-else-if="message.sender !== 'CHATBOT' && message.receiver === 'DRIVER'">{{ $t('chat.ticket.sender.agent') }}</span>
                <span v-else>{{ $t('chat.ticket.sender.driver') }}</span>
              </p>

              <p class="chat_tickets__ticket-box-message">
                {{ message.text }}
              </p>

              <p class="chat_tickets__ticket-box-date">
                {{ $t('chat.ticket.sendingBy') }} {{ message.channel }} {{ formatDate(message.sent_at.date) }}
              </p>
            </div>
          </div>
        </div>

        <div v-else class="chat_tickets__no-message">
          <span>{{ $t(noItemsText) }}</span>
          <span />
        </div>

        <div v-if="loadedMessage && openedType === 'comments'" class="chat_comment__form">
          <b-form-textarea
            id="textarea"
            v-model="newComment.comment"
            :placeholder="$t(`chat.form.comment.textarea.placeholder`)"
            :disabled="block_ui"
            rows="3"
            max-rows="6"
            class="chat_comment__form-textarea"
          />

          <label
            for="file-input"
            class="chat_comment__form-attachment-label"
          >
            <i class="chat_comment__form-attachment-label-icon fa fa-paperclip" :title="$t(`chat.icons.paperclip`)" />

            <b-button
              variant="link"
              :disabled="block_ui"
              class="chat_comment__form-attachment-save-button pl-0 pr-0"
              @click="sendComment"
            >

              {{ $t('chat.form.comment.buttons.confirm') }}
            </b-button>

            <b-button
              variant="link"
              :disabled="block_ui"
              class="chat_comment__form-attachment-cancel-button pl-0 pr-0"
              @click="cleanCommentForm"
            >
              {{ $t('chat.form.comment.buttons.clean') }}
            </b-button>
            <span
              v-if="newComment.file"
              class="chat_comment__form-attachment-label-text"
            >
              {{ newComment.file | formatFileName }}
            </span>
          </label>
          <input
            id="file-input"
            ref="inputFile"
            type="file"
            accept="application/pdf,image/*"
            :disabled="block_ui"
            class="chat_comment__form-attachment-input"
            @change="onFileSelected($event)"
          >
        </div>
      </b-col>
    </b-row>

    <b-row class="chat-tickets__pagination">
      <b-col md="12" class="mt-4 mb-5">
        <b-pagination
          v-model="paginationConfig.currentPage"
          :total-rows="rows"
          :per-page="paginationConfig.perPage"
          aria-controls="my-table"
          :disabled="block_ui || !hasTickets || hasErrorTicket"
          align="center"
          class="mb-0"
          @change="changePage"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TICKETS_GET from '@graphql/chat-bot/queries/get-tickets.gql';
import TICKET_GET from '@graphql/chat-bot/queries/get-ticket.gql';
import COMMENTS_GET from '@graphql/chat-bot/queries/get-comments.gql';
import TAGS_GET from '@graphql/chat-bot/queries/get-tags.gql';
import CREATE_COMMENT from '@graphql/chat-bot/mutations/create-comment.gql';
import { currentLanguage } from '@utils/language';
import { providers, perPage } from '@enums/chat';
import { TIMEZONE } from '@enums/regions';
import { isBrazil } from '@utils/helper-region'
import { formatDateTimeZone } from '@utils/formatters/date'
import { mapGetters } from 'vuex'
import axios from 'axios'
import { toBase64 } from '@utils/upload';

export default {
  name: 'ChatTickets',
  data () {
    return {
      block_ui: true,
      loading: false,
      loadingTicket: false,
      paginationConfig: {
        perPage: 5,
        currentPage: 1,
        totalItems: 0
      },
      perPage: 5,
      tickets: [],
      ticketsContent: [],
      hasTickets: false,
      hasErrorTicket: false,
      activeTicket: '',
      activeTicketType: '',
      loadingMessage: false,
      loadedMessage: false,
      hasContent: false,
      isOpenedTicket: false,
      openedType: '',
      carPlate: '',
      provider: null,
      openingDate: '',
      cleanedOpeningDate: false,
      protocol_number: '',
      newComment: {
        comment: '',
        file: ''
      }
    };
  },
  async mounted() {
    this.block_ui = true
    await this.getTickets()
  },
  computed: {
    ...mapGetters('user', {
      user: 'attributes'
    }),
    noItemsText () {
      if(this.openedType === 'comments'){
        return 'chat.messages.noComments'
      }
      if(this.openedType === 'tickets'){
        return 'chat.messages.noMessages'
      }
        return 'chat.messages.noTags'
    },
    ticketsFilter () {
      let startDay = ''
      let endDay = ''

      if (this.openingDate) {
        const dateFormat = '{year}-{iso-month}-{date-pad} {hour-24-pad}:{minute-pad}:{second-pad}'
        let currentDate = this.$spacetime(this.openingDate)

        currentDate.goto(isBrazil() ? TIMEZONE.BR : TIMEZONE.MX)

        startDay = currentDate.startOf('day').format(dateFormat)
        endDay = currentDate.endOf('day').format(dateFormat)
      }

      const defaultFilter = {
        "where":{
          "provider": this.provider,
          "driver_id": this.$route.params.id,
          "protocol_number": this.protocol_number,
          "status": [
            "OPENED",
            "IN_PROGRESS",
            "CLOSED"
          ],
          "created_at": {
            "$between": [startDay, endDay]
          }
        },
        "order": [
          [
            "created_at",
            "DESC"
          ]
        ]
      }

      if (!this.provider) delete defaultFilter.where.provider
      if (!this.openingDate) delete defaultFilter.where.created_at
      if (!this.protocol_number) delete defaultFilter.where.protocol_number

      return defaultFilter
    },
    providersOptions () {
      const newProviders = providers.map(item => {
        item.text = this.$t(item.text)

        return item
      })

      return newProviders
    },
    perPageOptions () {
      return perPage
    },
    randomHeight () {
      let newHeight = ''
      const perPage = this.perPage

      switch (perPage) {
        case 7:
          newHeight = '900'
          break
        case 10:
          newHeight = '1390'
          break
        case 15:
          newHeight = '2085'
          break
        case 20:
          newHeight = '2780'
          break
        case 25:
          newHeight = '3475'
          break
        case 50:
          newHeight = '6950'
          break
        case 100:
          newHeight = '13900'
          break
        default:
          newHeight = '646'
          break
      }

      return newHeight
    },
    date_picker_props() {
      return {
        type: 'date',
        timePickerOptions: {
          start: '07:00',
          step: '00:30',
          end: '22:30'
        },
        disabledDays(value) {
          const today = value.getDate();
          if (today === 0) return today !== 1
        },
        shortcuts: false,
        clearable: true,
        editable: true,
        notBefore: this.today,
        notAfter: this.week,
        width: 'auto',
        format: 'DD/MM/YYYY',
      }
    },
    getLanguage() {
      return currentLanguage;
    },
    responsiveTicketsWidth () {
      return this.isOpenedTicket ? 7 : 12
    },
    responsiveTicketWidth () {
      return this.isOpenedTicket ? 5 : 0
    },
    rows () {
      return this.paginationConfig.totalItems
    }
  },
  filters: {
    formatDayMonth (value) {
      const createdAt = new Date(value)
      const day = createdAt.getDate()
      const month = createdAt.toLocaleString('default', { month: 'short' }).replace('.','')

      return `${day}/${month}`
    },
    formatFileName (value) {
      const fileType = value.type.split('/')[1]
      const fileName = value.name.substr(0, 20)

      return `${fileName}...${fileType}`
    }
  },
  methods: {
    modalId(i) {
      return 'modal' + i;
    },
    onFileSelected (event) {
      if (!event.target.files[0]) return
      this.newComment.file = event.target.files[0];
    },
    formatDate (value) {
      return formatDateTimeZone(new Date(value), 'DD/MM/YYYY HH:mm:ss')
    },
    async getTickets (newSearch = false) {
      let currentPage = this.paginationConfig.currentPage

      if (newSearch) {
        this.paginationConfig.currentPage = 1
        currentPage = 1
      }

      this.block_ui = true
      this.hasTickets = false
      this.tickets = []
      this.ticketsContent = []
      this.loading = true
      this.loadingTicket = false
      this.hasErrorTicket = false

      this.closeTicket()

      const perPage = this.paginationConfig.perPage
      this.perPage = this.paginationConfig.perPage

      await this.$apollo.query({
        query: TICKETS_GET,
        variables: {
          "limit": perPage,
          "page": currentPage,
          "filters": this.ticketsFilter,
          "filtersIncludes": {
            "messages": {
              "required": false
            }
          },
          "attributesIncludes": {
            "messages": [
              "id",
              "text",
              "support_agent_email",
              "channel",
              "sender",
              "receiver",
              "sent_at",
              "created_at",
              "updated_at"
            ]
          }
        }
      })
      .then(({ data }) => {
        let responseTickets = data.tickets.items
        this.paginationConfig.totalItems = data.tickets.total

        if (responseTickets.length) {
          this.hasTickets = true
          const formatedStatus = this.changeStatus(responseTickets)
          this.tickets = formatedStatus
        } else {
          this.hasTickets = false
          this.resetFilter()
        }
      })
      .catch(() => {
        this.hasErrorTicket = true
      })

      this.block_ui = false
      this.loading = false
      this.loadingTicket = true
    },
    async getItems (ticketId, type) {
      this.ticketsContent = []
      this.hasContent = false
      this.loadingMessage = true
      this.isOpenedTicket = true
      this.loadedMessage = false

      this.block_ui = true

      const currentQuery = (type) => {
        if(type === 'ticket') {
          return TICKET_GET
        }
        if(type === 'comments') {
          return COMMENTS_GET
        }
          return TAGS_GET
      }
      let currentVariables = {
        "filters": {
          "where":{
            "id": ticketId
          },
          "order": [
            [
              "created_at",
              "DESC"
            ]
          ]
        },
        "filtersIncludes": {
          "messages": {
            "required": false
          },
          "comments": {
            "required": false
          },
          "tags": {
            "required": false
          }
        },
        "attributesIncludes": {
          "messages": [
            "id",
            "text",
            "support_agent_email",
            "channel",
            "sender",
            "receiver",
            "sent_at",
            "created_at",
            "updated_at"
          ],
          "comments": [
            "id",
            "comment",
            "file_comment",
            "internal",
            "is_active",
            "support_agent_email",
            "support_ticket_id",
            "created_at",
            "updated_at"
          ],
          "supportTags": [
            "support_ticket_id",
            "support_tag_id",
            "support_agent_email",
            "is_active",
            "created_at",
          ],
        }
      }

      await this.$apollo.query({
        query: currentQuery(type),
        fetchPolicy: 'no-cache',
        variables: currentVariables
      })
        .then(({ data }) => {
          const items = data.tickets.items[0]
          const itemByType = (type) => {
            if(type === 'ticket') {
              return items.messages
            }
            if(type === 'comments') {
              return items.comments
            }
              return items.tags
          }
          const itemByTypeData = itemByType(type)
          this.hasContent = true

          if(type === 'ticket' && itemByTypeData.length > 0) {
            const msgs = this.sortingMessages(itemByTypeData)
            this.ticketsContent = msgs
          } else if(type === 'comments' && itemByTypeData.length > 0) {
            const comments = this.sortingMessages(items.comments, 'created_at')
            this.ticketsContent = comments
          } else if(type === 'tags' && itemByTypeData.length > 0) {
            const tags = items.tags
            this.ticketsContent = tags
          }
           else {
            this.hasContent = false
          }
        })
        .catch(() => {
          this.hasContent = false
        })

      this.block_ui = false
      this.loadingMessage = false
      this.loadedMessage = true
    },
    sendComment () {
      const configSwal = {
        type: 'warning',
        title: this.$i18n.t('chat.modals.comment.create.title'),
        text: this.$i18n.t('chat.modals.comment.create.text'),
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t('chat.modals.comment.create.buttons.confirm'),
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('chat.modals.comment.create.buttons.cancel'),
        cleanButtonText: this.$i18n.t('chat.modals.comment.create.buttons.clean'),
        showLoaderOnConfirm: true,
        preConfirm: () => this.createComment(),
      }
      this.$swal(configSwal)
    },
    async createComment () {
      let file = ''
      let fileUrl = ''

      this.block_ui = true

      if (this.newComment.file) {
        file = await this.sendFile(this.newComment.file)

        if (file.status !== 'success') throw new Error('Error on upload image')

        fileUrl = file.body.absolute_path
      }

      const comment = (this.newComment.comment) ? this.newComment.comment : 'without comment'

      await this.$apollo.mutate({
        mutation: CREATE_COMMENT,
        variables: {
          input: {
            support_ticket_id: this.activeTicket,
            support_agent_email: this.user.email,
            file_comment: fileUrl,
            comment: comment,
            is_active: true,
            internal: true
          }
        }
      })
        .then(() => {
          this.openedType = 'comments'

          this.newComment.comment = ''
          this.newComment.file = ''

          this.getItems(this.activeTicket, 'comments')
        })
        .catch(() => {
          this.showErrorModal();
        });
    },
    async sendFile (file) {
      return axios.post(`${process.env.KOVI_API_URL}/docs/upload-file-comment`, {
        file: await toBase64(file),
        filename: file.name,
      })
        .then(res => {
          return res.data
        })
        .catch(err => {
          return err
        })
    },
    setPerPage (value) {
      if (value !== this.paginationConfig.perPage) {
        this.paginationConfig.perPage = value
      }
    },
    resetFilter () {
      this.paginationConfig.perPage = 5
      this.paginationConfig.currentPage = 1
      this.paginationConfig.totalItems = 0
    },
    async openTicket (type, ticketId) {
      if (this.block_ui) return

      if (this.activeTicket === ticketId) {
        this.closeTicket()
      } else {
        this.openedType = type
        this.activeTicketType = type
        this.activeTicket = ticketId

        await this.getItems(ticketId, type)
      }
    },
    closeTicket () {
      this.activeTicket = ''
      this.activeTicketType = ''
      this.isOpenedTicket = false
      this.openedType = ''
      this.ticketsContent = []
      this.hasContent = false
      this.loadingMessage = false
      this.loadedMessage = false
      this.block_ui = false
    },
    cleanCommentForm () {
      this.$refs['inputFile'].value = ''
      this.newComment.comment = ''
      this.newComment.file = ''
    },
    async changePage (event) {
      this.paginationConfig.currentPage = event
      this.closeTicket()
      await this.getTickets()
    },
    changeStatus (tickets) {
      const newTickets = tickets.map(ticket => {
        switch (ticket.status) {
          case 'IN_PROGRESS':
            ticket.statusColor = '#E91E63'
            break
          case 'CLOSED':
            ticket.statusColor = '#42A5F5'
            break
          default:
            ticket.statusColor = '#66BB6A'
            break
        }

        return ticket
      })

      return newTickets
    },
    sortingMessages (messages, orderBy = 'sent_at') {
      messages.sort((a, b) => {
        const result =
          new Date(a[orderBy].date).getTime() > new Date(b[orderBy].date).getTime()
            ? 1
            : -1
        return result
      })

      return messages
    },
    pdfOrImage (file) {
      const splittedFile = file.split('/')
      const splittedFileLength = splittedFile.length
      let fileType = 'image'

      if (splittedFile[splittedFileLength - 1].search('pdf') !== -1) fileType = 'pdf'

      return fileType
    },
    showErrorModal() {
      this.$swal({
        type: 'error',
        title: 'Ocorreu um erro!',
        text: 'Verifique as informações novamente',
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/chat-tickets.scss';
</style>
