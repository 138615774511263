var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "chat p-0", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "chat-tickets__form" },
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass:
                        "chat-tickets__form-plate-input col-md-3 col-sm-12",
                      attrs: { label: _vm.$t(`chat.form.carPlate.label`) },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.$t(`chat.form.carPlate.label`),
                          disabled: _vm.block_ui,
                        },
                        model: {
                          value: _vm.carPlate,
                          callback: function ($$v) {
                            _vm.carPlate = $$v
                          },
                          expression: "carPlate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-2 col-sm-12",
                      attrs: { label: _vm.$t(`chat.form.perPage.label`) },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          value: _vm.paginationConfig.perPage,
                          options: _vm.perPageOptions,
                          disabled: _vm.block_ui,
                        },
                        on: { change: _vm.setPerPage },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-md-2 col-sm-12",
                      attrs: { label: _vm.$t(`chat.form.provider.label`) },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.providersOptions,
                          disabled: _vm.block_ui,
                        },
                        model: {
                          value: _vm.provider,
                          callback: function ($$v) {
                            _vm.provider = $$v
                          },
                          expression: "provider",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.$t(`chat.form.openingDate.label`) } },
                    [
                      _c(
                        "datepicker",
                        _vm._b(
                          {
                            attrs: {
                              lang: _vm.getLanguage,
                              disabled: _vm.block_ui,
                            },
                            model: {
                              value: _vm.openingDate,
                              callback: function ($$v) {
                                _vm.openingDate = $$v
                              },
                              expression: "openingDate",
                            },
                          },
                          "datepicker",
                          _vm.date_picker_props,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass:
                        "chat-tickets__form-protocol_number-input col-md-3 col-sm-12",
                      attrs: {
                        label: _vm.$t(`chat.form.protocol_number.label`),
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.$t(
                            `chat.form.protocol_number.label`
                          ),
                          disabled: _vm.block_ui,
                        },
                        model: {
                          value: _vm.protocol_number,
                          callback: function ($$v) {
                            _vm.protocol_number = $$v
                          },
                          expression: "protocol_number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { staticClass: "col-md-1 col-sm-12" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "chat-tickets__form-button",
                          attrs: {
                            type: "submit",
                            variant: "info",
                            block: "",
                            disabled: _vm.block_ui,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getTickets(true)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-search" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "b-row",
            { staticClass: "chat-tickets__loader" },
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c("div", { staticClass: "chat-tickets__loader-wrapper" }, [
                      _c("i", {
                        staticClass:
                          "chat-tickets__loader-icon fa fa-spin fa-cog",
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && (!_vm.hasTickets || _vm.hasErrorTicket)
        ? _c(
            "b-row",
            { staticClass: "chat-tickets__result-messages" },
            [
              _c(
                "b-col",
                {
                  staticClass: "chat-tickets__result-message",
                  attrs: { md: "12" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("chat.messages.noItems")) + " ")]
              ),
            ],
            1
          )
        : _c(
            "b-row",
            {
              staticClass: "chat-tickets__list",
              style: { "max-height": `${_vm.randomHeight}px` },
            },
            [
              _c(
                "b-col",
                {
                  class: [
                    "chat-tickets__list-cards",
                    _vm.isOpenedTicket
                      ? "chat-tickets__list-cards--opened"
                      : "",
                  ],
                  attrs: {
                    xs: _vm.responsiveTicketsWidth,
                    sm: _vm.responsiveTicketsWidth,
                    md: _vm.responsiveTicketsWidth,
                  },
                },
                _vm._l(_vm.tickets, function (ticket, index) {
                  return _c(
                    "b-card",
                    {
                      key: index,
                      class: [
                        "chat-tickets__list-card",
                        _vm.activeTicket === ticket.id
                          ? "chat-tickets__list-card--active"
                          : "",
                      ],
                      attrs: { "no-body": "" },
                    },
                    [
                      _c("div", { staticClass: "chat-tickets__card-content" }, [
                        _c(
                          "div",
                          { staticClass: "chat-tickets__date-content" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "chat-tickets__date-content-text",
                              },
                              [
                                _c(
                                  "p",
                                  { style: { color: ticket.statusColor } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatDayMonth")(
                                            ticket.created_at.date
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("span", {
                              staticClass:
                                "chat-tickets__date-content-separator",
                              style: { background: ticket.statusColor },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "chat-tickets__driver-content" },
                          [
                            _c(
                              "span",
                              { staticClass: "chat-tickets-protocol" },
                              [_vm._v(_vm._s(ticket.protocol_number))]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "chat-tickets__driver-content-count-status",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "chat-tickets__driver-content-count",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(ticket.messages.length) +
                                        " " +
                                        _vm._s(
                                          _vm.$t(
                                            `chat.tickets.count.${
                                              ticket.messages.length === 1
                                                ? "one"
                                                : "others"
                                            }`
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("p", [
                              _c(
                                "span",
                                {
                                  class: [
                                    "chat-tickets__driver-status",
                                    `chat-tickets__driver-status--${ticket.status}`,
                                  ],
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(`chat.status.${ticket.status}`)
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "chat-tickets__driver-content-provider",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        `chat.providers.${ticket.provider}`
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c("span", {
                                staticClass:
                                  "chat-tickets__driver-content-comments fa fa-comments",
                                attrs: { title: _vm.$t(`chat.icons.comments`) },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTicket("comments", ticket.id)
                                  },
                                },
                              }),
                              _c("span", {
                                staticClass:
                                  "chat-tickets__driver-content-tags fa fa-tags",
                                attrs: { title: _vm.$t(`chat.icons.tags`) },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTicket("tags", ticket.id)
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "chat-tickets__driver-button-view pl-0 pr-0",
                                attrs: {
                                  variant: "link",
                                  disabled: _vm.block_ui,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openTicket("ticket", ticket.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "chat-tickets__driver-icons" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "chat-tickets-icon-eyes-icon fa fa-eye",
                                      attrs: {
                                        title: _vm.$t(`chat.icons.eyes`),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _vm.isOpenedTicket
                ? _c(
                    "b-col",
                    {
                      class: [
                        "chat-tickets__list-tickets",
                        "no-padding",
                        _vm.isOpenedTicket
                          ? "chat-tickets__ticket--opened"
                          : "",
                      ],
                      style: { "max-height": `${_vm.randomHeight - 12}px` },
                      attrs: {
                        xs: _vm.responsiveTicketWidth,
                        sm: _vm.responsiveTicketWidth,
                        md: _vm.responsiveTicketWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "chat-tickets__ticket-button" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "chat_tickets__ticket-close-button",
                              attrs: { disabled: _vm.block_ui },
                              on: { click: _vm.closeTicket },
                            },
                            [_vm._v(" X ")]
                          ),
                        ],
                        1
                      ),
                      _vm.loadingMessage
                        ? _c(
                            "div",
                            { staticClass: "chat-tickets__ticket-loader" },
                            [
                              _c("transition", { attrs: { name: "fade" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "chat-tickets__loader-wrapper",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "chat-tickets__loader-icon fa fa-spin fa-cog",
                                    }),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm.loadedMessage && _vm.hasContent
                        ? _c(
                            "div",
                            {
                              staticClass: "chat_tickets__items",
                              style: {
                                height:
                                  _vm.openedType === "comments" ? "80%" : "",
                              },
                            },
                            [
                              _vm.openedType === "comments"
                                ? _c(
                                    "div",
                                    { staticClass: "chat_tickets__comment" },
                                    _vm._l(
                                      _vm.ticketsContent,
                                      function (message, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "chat_tickets__comment-box chat_tickets__comment-driver-box",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "chat_tickets__comment-box-agent",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      message.support_agent_email
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "chat_tickets__comment-box-content",
                                              },
                                              [
                                                message.file_comment
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "chat_tickets__comment-content-link",
                                                        attrs: {
                                                          href: message.file_comment,
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _vm.pdfOrImage(
                                                          message.file_comment
                                                        ) === "image"
                                                          ? _c("img", {
                                                              staticClass:
                                                                "chat_tickets__content-link-image",
                                                              attrs: {
                                                                src: message.file_comment,
                                                              },
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "chat_tickets__content-link-icon fa fa-file-pdf-o",
                                                            }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                message.comment !==
                                                "without comment"
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "chat_tickets__comment-content-message",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              message.comment
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "chat_tickets__comment-box-date",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        message.created_at.date
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm.openedType === "tags"
                                ? _c(
                                    "div",
                                    { staticClass: "chat_tickets__tags" },
                                    _vm._l(
                                      _vm.ticketsContent,
                                      function (tag, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass:
                                              "chat_tickets__tags-box chat_tickets__tags-box",
                                          },
                                          [
                                            !tag.SupportTicketsTag.is_active
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "chat_tickets__no-tags-active",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "chat.messages.noTagsActive"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                    _c("span"),
                                                  ]
                                                )
                                              : _vm._e(),
                                            tag.SupportTicketsTag.is_active
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "chat_tickets__tags-box-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chat_tickets__tags-header",
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(tag.name) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chat_tickets__tags-body",
                                                      },
                                                      [
                                                        _c("p", [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "fa fa-calendar",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      tag
                                                                        .SupportTicketsTag
                                                                        .created_at
                                                                        .date
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                        _c("p", [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "fa fa-user",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    tag
                                                                      .SupportTicketsTag
                                                                      .support_agent_email
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "chat_tickets__ticket" },
                                    _vm._l(
                                      _vm.ticketsContent,
                                      function (message, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            class: [
                                              "chat_tickets__ticket-box",
                                              ["STUDIO", "CHATBOT"].includes(
                                                message.sender
                                              )
                                                ? "chat_tickets__ticket-bot-box"
                                                : "chat_tickets__ticket-driver-box",
                                            ],
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "chat_tickets__ticket-box-sender",
                                              },
                                              [
                                                message.sender === "CHATBOT" &&
                                                message.receiver === "DRIVER"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "chat.ticket.sender.chatbot"
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : message.sender ===
                                                      "STUDIO" &&
                                                    message.receiver ===
                                                      "DRIVER"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "chat.ticket.sender.studio"
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : message.sender !==
                                                      "CHATBOT" &&
                                                    message.receiver ===
                                                      "DRIVER"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "chat.ticket.sender.agent"
                                                          )
                                                        )
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "chat.ticket.sender.driver"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "chat_tickets__ticket-box-message",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(message.text) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "chat_tickets__ticket-box-date",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "chat.ticket.sendingBy"
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(message.channel) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.formatDate(
                                                        message.sent_at.date
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "chat_tickets__no-message" },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t(_vm.noItemsText))),
                              ]),
                              _c("span"),
                            ]
                          ),
                      _vm.loadedMessage && _vm.openedType === "comments"
                        ? _c(
                            "div",
                            { staticClass: "chat_comment__form" },
                            [
                              _c("b-form-textarea", {
                                staticClass: "chat_comment__form-textarea",
                                attrs: {
                                  id: "textarea",
                                  placeholder: _vm.$t(
                                    `chat.form.comment.textarea.placeholder`
                                  ),
                                  disabled: _vm.block_ui,
                                  rows: "3",
                                  "max-rows": "6",
                                },
                                model: {
                                  value: _vm.newComment.comment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.newComment, "comment", $$v)
                                  },
                                  expression: "newComment.comment",
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "chat_comment__form-attachment-label",
                                  attrs: { for: "file-input" },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "chat_comment__form-attachment-label-icon fa fa-paperclip",
                                    attrs: {
                                      title: _vm.$t(`chat.icons.paperclip`),
                                    },
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "chat_comment__form-attachment-save-button pl-0 pr-0",
                                      attrs: {
                                        variant: "link",
                                        disabled: _vm.block_ui,
                                      },
                                      on: { click: _vm.sendComment },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "chat.form.comment.buttons.confirm"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "chat_comment__form-attachment-cancel-button pl-0 pr-0",
                                      attrs: {
                                        variant: "link",
                                        disabled: _vm.block_ui,
                                      },
                                      on: { click: _vm.cleanCommentForm },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "chat.form.comment.buttons.clean"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.newComment.file
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "chat_comment__form-attachment-label-text",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatFileName")(
                                                  _vm.newComment.file
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("input", {
                                ref: "inputFile",
                                staticClass:
                                  "chat_comment__form-attachment-input",
                                attrs: {
                                  id: "file-input",
                                  type: "file",
                                  accept: "application/pdf,image/*",
                                  disabled: _vm.block_ui,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onFileSelected($event)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
      _c(
        "b-row",
        { staticClass: "chat-tickets__pagination" },
        [
          _c(
            "b-col",
            { staticClass: "mt-4 mb-5", attrs: { md: "12" } },
            [
              _c("b-pagination", {
                staticClass: "mb-0",
                attrs: {
                  "total-rows": _vm.rows,
                  "per-page": _vm.paginationConfig.perPage,
                  "aria-controls": "my-table",
                  disabled:
                    _vm.block_ui || !_vm.hasTickets || _vm.hasErrorTicket,
                  align: "center",
                },
                on: { change: _vm.changePage },
                model: {
                  value: _vm.paginationConfig.currentPage,
                  callback: function ($$v) {
                    _vm.$set(_vm.paginationConfig, "currentPage", $$v)
                  },
                  expression: "paginationConfig.currentPage",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }