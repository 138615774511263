export const providers = [
  {
    value: null,
    text: 'chat.providers.ALL'
  },
  {
    value: 'TWILIO',
    text: 'chat.providers.TWILIO'
  },
  {
    value: 'ZD',
    text: 'chat.providers.ZD'
  },
  {
    value: 'RENTAL',
    text: 'chat.providers.RENTAL'
  },
  {
    value: 'OTHERS',
    text: 'chat.providers.OTHERS'
  }
]

export const perPage = [
  {
    value: 5,
    text: '5'
  },
  {
    value: 7,
    text: '7'
  },
  {
    value: 10,
    text: '10'
  },
  {
    value: 15,
    text: '15'
  },
  {
    value: 20,
    text: '20'
  },
  {
    value: 25,
    text: '25'
  },
  {
    value: 50,
    text: '50'
  },
  {
    value: 100,
    text: '100'
  }
]

export const events = [
  {
    value: null,
    text: 'chat.events.ALL'
  },
  {
    value: 'EMAIL',
    text: 'chat.events.EMAIL'
  },
  {
    value: 'SMS',
    text: 'chat.events.SMS'
  },
  {
    value: 'OTHERS',
    text: 'chat.events.OTHERS'
  },
]
